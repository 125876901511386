import {environment as rivalEnvironment} from "./environment.rival";

export const environment = {
  ...rivalEnvironment,

  rivalName: "betcave",
  casinoId: 71,
  GA_TRACKING:'G-70PJC92473',

  rivalChatGroupName: "Bet Cave",
  prettyName: "BetCave",
  contactEmail: "support@casinobetcave.com",
  docsEmail: "documents@casinobetcave.email",

  //SEO
  metaDescription: "Join Betcave Casino for iGaming slots, live dealer table games, exclusive promotions, no deposit bonuses and many more. Granted free spins, VIP rewards, secure mobile play, and 24/7 support. Win big real money with fair play and responsible, fast gaming!",
  logoName: "betcave-online-casino-logo.png",
  logoAltText: "BetCave online casino logo with bold brown and gold text featuring a cave-inspired design popular for slots and online gambling bonuses."
};


